import ITTIModel from '../../ittijs/ITTIModel';
export default class extends ITTIModel {
	constructor(className) {
		super(className||'Devices');
	}
	getHiddenFieldsByType(deviceType) {
		return this.modelData && this.modelData['hiddenFieldsByType'][deviceType] || [];
	}
	async getDeviceTypes(){
		return (await this.fieldsAsync)['device_type_code'].options;
	}
	async isDeviceNDuplicate(utility, number, id){
		return await this.fetch('isDeviceNDuplicate', {utility, number,id});
	}
	getRoomOptions(utility, device_type_code){
		return this.fetch('getRoomOptions', {utility, device_type_code});
	}
}